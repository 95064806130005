<template>
  <div
    role="alert"
    class="alert alert-warning mt-4 d-flex justify-content-center align-items-center"
  >
    <div>
      <span v-if="project.status === 'ACTIVE'">{{ $t('general.activeListing') }} </span>
      <span v-else-if="project.status === 'NOT_ACTIVE'">{{ $t('general.inactiveListing') }} </span>
      <span v-else-if="project.status === 'DRAFT'">{{ $t('general.draftListing') }} </span>
      <span v-else-if="project.status === 'EXPIRED'">{{ $t('general.expiredListing') }} </span>
      <!--      <a-->
      <!--        :href="-->
      <!--          `https://api.whatsapp.com/send?phone=${$t('general.rentfixNumber')}&text=${textMessage}`-->
      <!--        "-->
      <!--        >{{ $t('general.inactiveProject2') }}</a-->
      <!--      >-->
    </div>
  </div>
</template>

<script>
export default {
  name: 'project-detail-not-active',
  props: ['project'],
  computed: {
    // textMessage() {
    //   return (
    //     encodeURIComponent(
    //       `Halo, saya ingin mencari properti yang serupa dengan properti ${this.listing.listing_title}`,
    //     ) + `%0a${this.listing.links ? this.listing.links.full_url : ''}`
    //   );
    // },
  },
};
</script>
